import React, { useEffect } from 'react';
import './App.css';
import Swipe from './swipe';
import backpic from './img/back.jpeg';
import logo from './img/edaplogo.png'
import { Facebook } from 'react-feather';
import emailjs from 'emailjs-com';

function App() {
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.navbar');
      const logo = document.querySelector('.logo');
  
      if (window.scrollY > 10) {
        navbar.classList.add('scrolled');
        logo.classList.add('hide-logo'); // Add class to hide logo
      } else {
        navbar.classList.remove('scrolled');
        logo.classList.remove('hide-logo'); // Remove class to show logo again
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const ContactForm = () => {
    const sendEmail = (e) => {
      e.preventDefault();
      
      emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
        .then((result) => {
          console.log('Email sent:', result.text);
          alert("Your message has been sent successfully!");
        }, (error) => {
          console.log('Failed to send email:', error.text);
          alert("There was an error sending your message.");
        });
        
      e.target.reset();  // Clear the form after submission
    };
  
  

  return (
    <div className="App">
        <div className="top-nav">
          <a href="#home">
            <img src={logo} className="logo" style={{ width: '5%', position: 'fixed', top: 0, zIndex: 1}} />
          </a>
          <nav className="navbar">
            <ul className="nav-links">
              <li><a href="#home"><strong>EDAP-UNILIS</strong></a></li>
            </ul>
          </nav>
        </div>
        <body>
        <div className='page-history'>
          <div className='history-text'>
            <br/>
            <br/>
            <br/>
            <br/>
            <h3>HISTOIRE DE L'EDAP-UNILIS</h3>
            <div class="container">
                    <input type="checkbox" id="check"/>
                    <div class="content1">
                        <p>
                            L’École Secondaire EDAP, associée à l’Université de Lisala (UNILIS) en République Démocratique du Congo, propose une éducation secondaire visant à préparer les élèves aux filières académiques et professionnelles. En RDC, le système secondaire, qui dure environ six ans, se divise en parcours général et technique, permettant aux élèves de poursuivre des études supérieures ou d’accéder au marché du travail directement. Les élèves d’EDAP complètent le cycle d’orientation, une phase préparatoire qui les prépare au diplôme d’état une certification ouvrant l’accès à l’enseignement supérieur.
                        </p>
                        </div>
                    <div class="content-text">
                       <p>
                          Le programme inclut des matières clés comme les mathématiques, le français, les sciences et les sciences humaines, répondant aux objectifs nationaux de consolidation académique. Les parcours techniques et professionnels sont également disponibles, offrant des formations pratiques en mécanique, agriculture et enseignement, contribuant ainsi à l’économie congolaise.
                       </p>
                    </div>
                    <label for="check">Plus</label>
                </div>
          </div>
          <div className='image-place'>
            <br/>
            <br/>
            <br/>
            <br/>
            <Swipe></Swipe><br/>
          </div>
        </div>
        <div className='classs'>
      <div className='form'>
        <form action='https://formspree.io/f/xrbgelle' method='POST'>
          <h1 style={{fontFamily: 'monospace'}}>Contactez-nous</h1>
          <input type='text' name='name' required placeholder='Name'/>
          <input type='email' name='email' required placeholder='Email'/>
          {/* <input type='text' name='subject' required placeholder='Subject'/> */}
          <textarea name='message' required placeholder='Message'/>
          <button type='submit'>Submit</button>
        </form>
      </div>
    </div>
      </body>
      <footer> EDAP-UNILIS © All Rights Reserved.</footer>
    </div>
  );
}

export default App;
