import React, { useEffect } from 'react';
import Carousel from './Carousel';
import './scroll.css';
import pic1 from './img/pic_1.jpeg';
import pic2 from './img/pic_2.jpeg';
import pic3 from './img/pic_3.jpeg';
import pic4 from './img/pic_4.jpeg';
import pic5 from './img/pic_5.jpeg';
import pic6 from './img/pic_6.jpeg';
import pic7 from './img/pic_7.jpeg';
import pic8 from './img/pic_8.jpeg';
import pic9 from './img/pic_9.jpeg';
import pic10 from './img/pic_10.jpeg';
import pic11 from './img/pic_11.jpeg';
import pic12 from './img/pic_12.jpeg';
import pic13 from './img/pic_13.jpeg';
import pic14 from './img/pic_14.jpeg';
import pic15 from './img/pic_15.jpeg';
import pic16 from './img/pic_16.jpeg';
import pic17 from './img/pic_17.jpeg';
import pic18 from './img/pic_18.jpeg';
import pic19 from './img/pic_19.jpeg';
import pic20 from './img/pic_20.jpeg';
import pic21 from './img/pic_21.jpeg';
import pic22 from './img/pic_22.jpeg';
import pic23 from './img/pic_23.jpeg';

const slides = [pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8,
  pic9,
  pic10,
  pic11,
  pic12,
  pic13,
  pic14,
  pic15,
  pic16,
  pic17,
  pic18,
  pic19,
  pic20,
  pic21,
  pic22,
  pic23];

function Swipe() {

  return (
    <div className="Swipe">
      <section id="h" className="section">
        <div className="content">
          <div className="max-w-lg">
            <Carousel>
              {slides.map((src, index) => (
                <img key={index} src={src} alt={`Slide ${index}`} />
              ))}
            </Carousel>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Swipe;
